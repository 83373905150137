import {HIDE_SPINNER, SHOW_ERROR, SHOW_SPINNER, SHOW_SUCCESS} from '../../actionConstants';

export const messages = {
  LOGIN_START: [
    {action: SHOW_SPINNER},
  ],
  LOGIN_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Login successful!', type: 'success'}},
    },
  ],
  LOGIN_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Authentication failed!', type: 'error'}},
    },
  ],
  LOGOUT_START: [
    {action: SHOW_SPINNER},
  ],
  LOGOUT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Logout successful!', type: 'success'}},
    },
  ],
  LOGOUT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Logout failed!', type: 'warn'}},
    },
  ],
  FORGOT_PASSWORD_START: [
    {action: SHOW_SPINNER},
  ],
  FORGOT_PASSWORD_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Password reset successful!', type: 'success'}},
    },
  ],
  FORGOT_PASSWORD_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Password reset failed!', type: 'error'}},
    },
  ],
  GET_USER_PROFILE_START: [
    {action: SHOW_SPINNER},
  ],
  GET_USER_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
  ],
  GET_USER_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Please try in 5 min to login!', type: 'warn'}},
    },
  ],
  UPDATE_USER_PROFILE_START: [
    {action: SHOW_SPINNER},
  ],
  UPDATE_USER_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Profile updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_USER_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Profile update failed!', type: 'error'}},
    },
  ],
  CREATE_BRAND_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Brand created successfully!', type: 'success'}},
    },
  ],
  CREATE_BRAND_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Brand create failed!', type: 'error'}},
    },
  ],
  UPDATE_BRAND_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Brand updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_BRAND_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Brand update failed!', type: 'error'}},
    },
  ],
  CREATE_DIVISION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Division created successfully!', type: 'success'}},
    },
  ],
  CREATE_DIVISION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Division create failed!', type: 'error'}},
    },
  ],
  UPDATE_DIVISION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Division updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_DIVISION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Division update failed!', type: 'error'}},
    },
  ],
  CREATE_JOB_ROLE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Job Role created successfully!', type: 'success'}},
    },
  ],
  CREATE_JOB_ROLE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Job Role create failed!', type: 'error'}},
    },
  ],
  UPDATE_JOB_ROLE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Job Role updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_JOB_ROLE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Job Role update failed!', type: 'error'}},
    },
  ],
  CREATE_STATE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'State created successfully!', type: 'success'}},
    },
  ],
  CREATE_STATE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'State updated fail', type: 'error'}},
    },
  ],
  UPDATE_STATE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'State updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_STATE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'State with this name already exists!', type: 'error'}},
    },
  ],
  CREATE_PRODUCT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Product created successfully!', type: 'success'}},
    },
  ],
  CREATE_PRODUCT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Product create failed!', type: 'error'}},
    },
  ],
  UPDATE_PRODUCT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Product updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_PRODUCT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Product update failed!', type: 'error'}},
    },
  ],
  CREATE_TOWN_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Town created successfully!', type: 'success'}},
    },
  ],
  CREATE_TOWN_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Town create failed!', type: 'error'}},
    },
  ],
  UPDATE_TOWN_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Town updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_TOWN_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Town update failed!', type: 'error'}},
    },
  ],
  CREATE_HOLIDAY_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Holiday created successfully!', type: 'success'}},
    },
  ],
  CREATE_HOLIDAY_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Holiday create failed!', type: 'error'}},
    },
  ],
  UPDATE_HOLIDAY_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Holiday updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_HOLIDAY_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Holiday update failed!', type: 'error'}},
    },
  ],
  CREATE_OVERVIEW_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'overview updated successfully!', type: 'success'}},
    },
  ],
  CREATE_OVERVIEW_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'overview update failed!', type: 'error'}},
    },
  ],

  CREATE_STOCKIST_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Stockist created successfully!', type: 'success'}},
    },
  ],
  CREATE_STOCKIST_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Stockist create failed!', type: 'error'}},
    },
  ],
  UPDATE_STOCKIST_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Stockist updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_STOCKIST_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Stockist update failed!', type: 'error'}},
    },
  ],
  DEACTIVATE_DOCTOR_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor deactivate successfully!', type: 'success'}},
    },
  ],
  DEACTIVATE_DOCTOR_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Doctor deactivate failed!', type: 'error'}},
    },
  ],
  CREATE_DOCTOR_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor created successfully!', type: 'success'}},
    },
  ],
  CREATE_DOCTOR_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Doctor created failed!', type: 'error'}},
    },
  ],
  UPDATE_DOCTOR_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor update successfully!', type: 'success'}},
    },
  ],
  UPDATE_DOCTOR_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Doctor update failed!', type: 'error'}},
    },
  ],
  CREATE_CUSTOMER_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Customer link open successfully!', type: 'success'}},
    },
  ],
  CREATE_CUSTOMER_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Customer link open failed!', type: 'error'}},
    },
  ],
  CREATE_CHEMIST_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Chemist update successfully!', type: 'success'}},
    },
  ],
  CREATE_CHEMIST_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Chemist update failed!', type: 'error'}},
    },
  ],
  UPDATE_CHEMIST_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Chemist update successfully!', type: 'success'}},
    },
  ],
  UPDATE_CHEMIST_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Chemist update failed!', type: 'error'}},
    },
  ],
  CREATE_HOSPITAL_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Hospital update successfully!', type: 'success'}},
    },
  ],
  CREATE_HOSPITAL_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Hospital update failed!', type: 'error'}},
    },
  ],
  UPDATE_HOSPITAL_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Hospital update successfully!', type: 'success'}},
    },
  ],
  UPDATE_HOSPITAL_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Hospital update failed!', type: 'error'}},
    },
  ],
  ADD_DOCTOR_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor added successfully!', type: 'success'}},
    },
  ],
  ADD_DOCTOR_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Doctor add failed!', type: 'error'}},
    },
  ],

  CHEMIST_APPROVE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Chemist approve successfully!', type: 'success'}},
    },
  ],
  CHEMIST_APPROVE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Chemist approve failed!', type: 'error'}},
    },
  ],
  CHEMIST_REJECT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Chemist reject successfully!', type: 'success'}},
    },
  ],
  CHEMIST_REJECT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Chemist reject failed!', type: 'error'}},
    },
  ],
  DOCTOR_APPROVE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor approve successfully!', type: 'success'}},
    },
  ],
  DOCTOR_APPROVE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Doctor approve failed!', type: 'error'}},
    },
  ],
  DOCTOR_REJECT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor reject successfully!', type: 'success'}},
    },
  ],
  DOCTOR_REJECT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Doctor reject failed!', type: 'error'}},
    },
  ],
  DELETE_CUSTOMER_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Delete successfully!', type: 'success'}},
    },
  ],
  DELETE_CUSTOMER_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Delete failed!', type: 'error'}},
    },
  ],
  UNLOCK_EXPENCE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Request sent successfully!', type: 'success'}},
    },
  ],
  UNLOCK_EXPENCE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Request sent failed!', type: 'error'}},
    },
  ],
  CHANGE_PASSWORD_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Password updated successfully!', type: 'success'}},
    },
  ],
  CHANGE_PASSWORD_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Password updated failed!', type: 'error'}},
    },
  ],
  UPDATE_DIGITAL_PREFERENCE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Digital preferences updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_DIGITAL_PREFERENCE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Digital preferences failed to update!', type: 'error'}},
    },
  ],
  UPDATE_SPORT_PREFERENCE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Sport preferences updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_SPORT_PREFERENCE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Sport preferences failed to update!', type: 'error'}},
    },
  ],
  UPDATE_FOOD_PREFERENCE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Food preferences updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_FOOD_PREFERENCE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Food preferences failed to update!', type: 'error'}},
    },
  ],
  UPDATE_PERSONAL_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Personal Profile updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_PERSONAL_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Personal Profile failed to update!', type: 'error'}},
    },
  ],
  UPDATE_KOL_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol Profile updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_KOL_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol Profile failed to update!', type: 'error'}},
    },
  ],
  UPDATE_PRACTICING_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Practicing Profile updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_PRACTICING_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Practicing Profile failed to update!', type: 'error'}},
    },
  ],
  UPDATE_CLINICAL_ADDRESS_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Clinical address updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_CLINICAL_ADDRESS_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Practicing address failed to update!', type: 'error'}},
    },
  ],
  UPDATE_ADDITIONAL_INFO_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Additional info updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_ADDITIONAL_INFO_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Additional info failed to update!', type: 'error'}},
    },
  ],
  ADD_BRAND_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Brand added successfully!', type: 'success'}},
    },
  ],
  ADD_BRAND_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Brand added  failed !', type: 'error'}},
    },
  ],
  DELETE_BRAND_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Brand deleted successfully!', type: 'success'}},
    },
  ],
  DELETE_BRAND_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Brand deleted failed !', type: 'error'}},
    },
  ],
  UPDATE_DIVISION_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Division profile updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_DIVISION_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Division profile updated failed !', type: 'error'}},
    },
  ],
  UPDATE_PRACTICING_DETAIL_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Practicing details updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_PRACTICING_DETAIL_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Practicing details updated failed !', type: 'error'}},
    },
  ],
  UPDATE_OTHER_IMAGE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Other image updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_OTHER_IMAGE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Other image  failed to update!', type: 'error'}},
    },
  ],
  UPDATE_VISITING_CARD_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Visiting card updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_VISITING_CARD_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Visiting card failed to update!', type: 'error'}},
    },
  ],
  UPDATE_CONTACT_INFO_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Contact info updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_CONTACT_INFO_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Contact info failed to update!', type: 'error'}},
    },
  ],
  ADD_SAME_BRAND_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'This brand is already exist!', type: 'error'}},
    },
  ],
  ACCEPT_POSITIVE_NUMBER_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'All numeric values must be positive!', type: 'error'}},
    },
  ],
};
