export const RQ_GET_DOCTOR_BY_LOCATION_ID = {
  url: 'doctor/by-location-all/<%= locationId  %>',
  method: 'GET',
  auth: true,
};

export const RQ_PUT_DOCTOR_DEACTIVATE_BY_DOCTOR_ID = {
  url: 'doctor/deactivate/<%= doctorId  %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_DOCTOR_BY_CLASSIFICATION_LOCATION_ID = {
  url: 'doctor/by-classification/<%= locationId  %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_BEAT_BY_LOCATION_ID_API = {
  url:'beat/by-location/<%= locationId  %>',
  method:'GET',
  auth:true,
};

export const RQ_CREATE_DOCTOR_API = {
  url: 'doctor/createDoctor',
  method: 'POST',
  auth: true,
};

export const RQ_GET_DOCTOR_FIND_BY_ID = {
  url: 'doctor/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_CONTACT_INFO_ALL_FIELDS_API = {
  url: 'doctor/update-contact',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_DIVISION_PROFILE_ALL_FIELDS_API = {
  url: 'doctor/updateDoctor',
  method: 'POST',
  auth: true,
};

export const RQ_GET_BRAND_BY_LOCATION_ID_API = {
  url:'brand/by-location/<%= locationId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_BRAND_BY_DOCTOR_ID_API = {
  url:'doctorbrands/by-doctor/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_SAVE_DOCTOR_BRAND_BY_DOCTOR_ID_API = {
  url: 'doctorbrands/save/<%= doctorId %>/<%= brandId %>/<%= sequence %>',
  method: 'POST',
  auth: true,
};

export const RQ_UPDATE_SPORTS_PREFERENCES_ALL_FIELDS_API = {
  url: 'doctorsportpreference/save',
  method: 'POST',
  auth: true,
};

export const RQ_UPDATE_DIGITAL_PREFERENCES_ALL_FIELDS_API = {
  url: 'doctordigitalpreference/save',
  method: 'POST',
  auth: true,
};

export const RQ_UPDATE_FOOD_PREFERENCES_ALL_FIELDS_API = {
  url: 'doctorfoodpreference/save',
  method: 'POST',
  auth: true,
};

export const RQ_UPDATE_FAMILY_DETAILS_ALL_FIELDS_API = {
  url: 'doctorclandetails/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_HOSPITAL_TYPE_BY_TYPE_API = {
  url:'systemlov/by-type/<%= type  %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_MAJOR_FOCUS_AREA_TYPE_API = {
  url:'systemlov/by-type/<%= type  %>',
  method:'GET',
  auth:true,
};

export const RQ_UPDATE_DOCTOR_KOL_PROFILE_ALL_FIELDS_API = {
  url: 'doctorkolprofile/save',
  method: 'POST',
  auth: true,
};

export const RQ_POST_DOCTOR_POTENTIAL_SAVE_ALL_FIELDS_API = {
  url: 'doctorpotential/save',
  method: 'POST',
  auth: true,
};

export const RQ_POST_ADDRESS_SAVE_ALL_FIELDS_API = {
  url: 'address/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_DOCTOR_DEGREE_BY_TYPE_API = {
  url:'systemlov/by-type/<%= type  %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_CALL_DAY_BY_TYPE_API = {
  url:'systemlov/by-type/<%= type  %>',
  method:'GET',
  auth:true,
};

export const RQ_SAVE_DOCTOR_ADDITIONAL_INFO_API = {
  url: 'doctorinfo/save',
  method: 'POST',
  auth: true,
};

export const RQ_SAVE_PRACTICING_DETAILS_ALL_FIELDS_API = {
  url: 'doctorpractisingprofile/save',
  method: 'POST',
  auth: true,
};

export const RQ_POST_UPLOAD_IMAGE = {
  url: 'document/upload',
  method: 'POST',
  auth: true,
};

export const RQ_GET_PRACTICING_PROFILE_BY_DOCTOR_ID_API = {
  url:'doctorpractisingprofile/by-doctor/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_FOOD_PREFERENCE_BY_DOCTOR_ID_API = {
  url:'doctorfoodpreference/by-doctor/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_DIGITAL_PREFERENCE_BY_DOCTOR_ID_API = {
  url:'doctordigitalpreference/by-doctor/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_SPORT_PREFERENCE_BY_DOCTOR_ID_API = {
  url:'doctorsportpreference/by-doctor/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_DOCTOR_INFO_BY_DOCTOR_ID_API = {
  url:'doctorinfo/by-doctor/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_FAMILY_DETAILS_BY_DOCTOR_ID_API = {
  url:'doctorclandetails/by-doctor/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_KOL_DETAILS_BY_DOCTOR_ID_API = {
  url:'doctorkolprofile/by-doctor/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_DOCTOR_POTENTIAL_BY_DOCTOR_ID_API = {
  url:'doctorpotential/by-doctor/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_DOCUMENT_BY_OWNER_ID_TYPE_ID_API = {
  url:'document/by-owner-type/<%= ownerId %>/<%= typeId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_DOCTOR_FROM_UID_BY_LOCATION_ID = {
  url: 'doctor/my-universe/<%= locationId  %>',
  method: 'GET',
  auth: true,
};

export const RQ_POST_ADD_DOCTOR_VIA_UID = {
  url: 'doctor/create-via-uid/<%= doctorId  %>/<%= locationId  %>',
  method: 'POST',
  auth: true,
};

export const RQ_GET_CONFIG_BY_LOCATION_ID = {
  url: 'config/by-location/<%= locationId %>',
  method: 'GET',
  auth: true,
};

export const RQ_PUT_DOCTOR_DELETE_BY_DOCTOR_ID = {
  url: 'doctorbrands/delete/<%= doctorId  %>/<%= brandId  %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_DOCTOR_DUMP_BY_DOCTOR_ID = {
  url: 'doctordump/list/<%= doctorId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_DOCTOR_ADDRESS_BY_OWNER_ID_API = {
  url:'address/by-owner/<%= ownerId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_DOCTOR_360_LAST_TEN_VISIT_API = {
  url:'doctor/doc-360-last-ten-visit/<%= locationId %>/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_DOCTOR_360_LAST_TEN_VISIT_RCPA_API = {
  url:'doctor/doc-360-last-ten-visit-rcpa/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_DOCTOR_CHEMIST_API = {
  url:'doctor/doc-360-doctor-chemist/<%= doctorId %>',
  method:'GET',
  auth:true,
};

export const RQ_GET_DOCTOR_360_VISIT_FREQUENCY = {
  url:'doctor/doc-360-doctor-visit-frequency/<%= doctorId %>',
  method:'GET',
  auth:true,
};
