import {Col, Space, Typography} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';
import './WelcomeHeader.less';

const WelcomeHeader = ({auth}) => {
  return (
    <Space>
      <Col className={'welcome-header-box'}>
        <Typography className={'welcome-admin-title'}>
        Infinity 3.0
        </Typography>
      </Col>
    </Space>
  );
};

WelcomeHeader.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(WelcomeHeader);
