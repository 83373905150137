export const RQ_BRAND_WISE_SPECIALITY_REPORT_API = {
  url: 'dashboards/brandWiseSpeciality/<%= divisionId  %>/<%= fromMonth %>/<%= toMonth %>/<%= brandId %>/<%= productId %>',
  method: 'GET',
  auth: true,
};

export const RQ_RCPA_TREND_REPORT_API = {
  url: 'dashboards/rcpaTrendReport/<%= divisionId %>/<%= fromMonth %>/<%= toMonth %>/<%= brandId %>/<%= productId %>/<%= specialityId %>/<%= categoryId %>/<%= periodType %>?segment=<%= segment %>',
  method: 'GET',
  auth: true,
};

export const RQ_EFFORT_HYGIENE_DOCTOR_VISIT_API = {
  url: 'dashboards/effortHygieneDoctorVisit/<%= divisionId %>/<%= fromDate %>/<%= toDate %>/<%= specialityId %>/<%= categoryId %>',
  method: 'GET',
  auth: true,
};

export const RQ_EFFORT_HYGIENE_MIRROR_STRATEGY_API = {
  url: 'dashboards/effortHygieneMirrorStrategy/<%= divisionId %>/<%= fromDate %>/<%= toDate %>/<%= specialityId %>/<%= categoryId %>',
  method: 'GET',
  auth: true,
};

export const RQ_EFFORT_HYGIENE_TOTAL_DML_API = {
  url: 'dashboards/effortHygieneTotalDML/<%= divisionId %>/<%= specialityId %>/<%= categoryId %>',
  method: 'GET',
  auth: true,
};

export const RQ_TGT_SALES_COLLECTION_API = {
  url: 'dashboards/target-sales-collection-report/<%= divisionId %>/<%= fromMonth %>/<%= toMonth %>/<%= brandId %>/<%= productId %>/<%= periodType %>',
  method: 'GET',
  auth: true,
};

export const RQ_PRIMARY_SECONDARY_RCPA_COLLECTION_API = {
  url: 'dashboards/primary-secondary-rcpa-collection-report/<%= divisionId %>/<%= fromMonth %>/<%= toMonth %>/<%= brandId %>/<%= productId %>/<%= periodType %>',
  method: 'GET',
  auth: true,
};

export const RQ_EFFORT_HYGIENE_DR_MET_AND_COVERAGE_CHART_API = {
  url: 'dashboards/drMetAndCoverageChart/<%= divisionId %>/<%= fromDate %>/<%= toDate %>/<%= specialityId %>/<%= categoryId %>',
  method: 'GET',
  auth: true,
};

export const RQ_EFFORT_HYGIENE_CALLS_AND_CALL_AVG_API = {
  url: 'dashboards/callsAndCallAvgChart/<%= divisionId %>/<%= fromDate %>/<%= toDate %>/<%= specialityId %>/<%= categoryId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_DML_LIST_DETAILS_API = {
  url: 'dashboards/dml-list/<%= locationId %>?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_FREQUENCY_DOCTOR_LIST_API = {
  url: 'dashboards/frequency-dml-list/<%= locationId %>/<%= visitCount %>/<%= fromDate %>/<%= toDate %>?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_MIRROR_DOCTOR_LIST_API = {
  url: 'dashboards/mirror-dml-list/<%= locationId %>/<%= visitCount %>/<%= fromDate %>/<%= toDate %>?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_SPECIALITY_WISE_DOCTOR_LIST_API = {
  url: 'dashboards/brand-speciality-dml-list/<%= locationId %>/<%= speciality %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_SPECIALITY_WISE_RX_DOCTOR_LIST_API = {
  url: 'dashboards/brand-speciality-rx-dml-list/<%= locationId %>/<%= fromDate %>/<%= toDate %>/<%= speciality %>?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_RCPA_DML_LIST_API = {
  url: 'dashboards/rcpa-dml-list/<%= locationId %>/<%= fromMonth %>/<%= toMonth %>/<%= periodType %>?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_PRIMARY_COLLECTION_SECONDARY_STOCKIST_LIST_API = {
  url: 'dashboards/primary-collection-secondary-stockist-list/<%= locationId %>/<%= fromMonth %>/<%= toMonth %>?',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_REVIEW_DASHBOARD_SALES_DATA_MTD_API = {
  url: 'dashboards/review-mtd-sales-data/<%= locationId %>/<%= monthYear %>',
  method: 'GET',
  auth: true,
};

export const RQ_REVIEW_DASHBOARD_SALES_DATA_YTD_API = {
  url: 'dashboards/review-ytd-sales-data/<%= locationId %>/<%= monthYear %>',
  method: 'GET',
  auth: true,
};

export const RQ_REVIEW_DASHBOARD_RCPA_DATA_API = {
  url: 'dashboards/review-rcpa-data/<%= locationId %>/<%= monthYear %>',
  method: 'GET',
  auth: true,
};

export const RQ_REVIEW_DASHBOARD_EFFORT_DATA_API = {
  url: 'dashboards/review-effort-data/<%= locationId %>/<%= fromDate %>/<%= toDate %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_MISSED_DML_LIST_API = {
  url: 'dashboards/missed-dml-list/<%= locationId %>/<%= fromDate %>/<%= toDate %>?',
  method: 'GET',
  auth: true,
  search: true,
};
