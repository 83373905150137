import {useQuery} from '@tanstack/react-query';
import {rqCreateSearchRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_GET_EVENT_TOPIC_LIST, RQ_GET_WHATSAPP_DATA_BY_LOCATION_ID} from '@app/reactQuery/apis/constants';

export const RQUseGetEventTopicList = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_event_topic_list', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_EVENT_TOPIC_LIST,
        null,
        certificate,
        null,
        signal,
      );
    },
    ...options,
  });
};

export const RQUseGetWhatsappDataByLocationId = (payload, options = {}) => {
  return useQuery({
    queryKey:['rq_get_whatsapp_data_by_location_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {certificate, locationId, campaign} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_WHATSAPP_DATA_BY_LOCATION_ID,
        null,
        certificate,
        {locationId: locationId, campaign: campaign},
        signal,
      );
    },
    ...options,
  });
};
