export const RQ_GET_EVENT_TOPIC_LIST = {
  url: 'whatsappdata/list',
  method: 'GET',
  auth: true,
};

export const RQ_GET_WHATSAPP_DATA_BY_LOCATION_ID = {
  url: 'whatsappdata/dashboard/<%= locationId %>/<%= campaign %>',
  method: 'GET',
  auth: true,
};
